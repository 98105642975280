import React,{useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const BlogSlider = ({ teamMembers }) => {
    const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className="testi-slider-area">
      <Slider {...settings} ref={sliderRef}>
      {teamMembers.map((member, index) => (
        <div key={index} className='row vs-carousel'>
          <div className='col-lg-12'>
          <div className="vs-blog blog-card">
              <div className="blog-img">
                <a href="/">
                  <img src={member.image} alt="blog" />
                </a>
              </div>
              <div className="blog-content shadow-none">
                <h4 className="blog-title">{member.title}</h4>
              </div>
            </div>

          </div>
        </div>
      ))}
      </Slider>
      <div className="vs-icon-box testi-1 d-none d-xl-block" onClick={goToPrevSlide}>
        <button data-slick-prev="#testi2" className="icon-btn style-3 arrow-left mb-15">
          <i className="far fa-arrow-left"></i>
        </button>
        <button data-slick-next="#testi2" className="icon-btn style-3 arrow-right" onClick={goToNextSlide}>
          <i className="far fa-arrow-right"></i>
        </button>
      </div>
    </div>
  );
};

export default BlogSlider;
