
import TeamSlider from '../components/TeamSlider'
import slide1 from '../assets/img/team/t1.jpeg';
import slide2 from '../assets/img/team/t-2.jpeg';
import slide3 from '../assets/img/team/t-5.jpeg';
import slide4 from '../assets/img/team/t-4.jpeg';

const Team = () => {
    const teamMembersData = [
        {
          image: slide1,
          name: 'Mrs.JAYA CHITRA. R',
          designation: 'Nursing Staff',
        },
        {
          image: slide2,
          name: 'Ms.PRIYADARSHINI. K',
          designation: 'Day Care Staff',
        },
        {
            image: slide3,
            name: 'Ms.GAYATHRI. M',
            designation: 'Teaching Staff',
          },
          {
            image: slide4,
            name: 'Mrs.Yeluru Srinitya Reddy',
            designation: 'Teaching Staff',
          },
      ];
     
    return(
   <>
        <section className="team-section bg-smoke space-extra">
        <div className="container">
            <div className="row text-center justify-content-center">
                <div className="col-xl-6 col-lg-7 col-md-8 col-sm-9">
                    <div className="title-area">
                        <span className="sub-title">Our Staff</span>
                        <h2 className="sec-title">Most Dedicated Staff For Your Child</h2>
                    </div>
                </div>
            </div>
        <TeamSlider teamMembers={teamMembersData} />

        </div>
        <div className="shape-before"><img src={require("../assets/img/shape/before-shape-5.png")} alt="shape"/></div>
        <div className="shape-after"><img src={require("../assets/img/shape/after-shape-5.png")} alt="shape"/></div>

        <div className="shape-mockup movingX d-none d-hd-block" data-top="12%" data-left="6%" style={{top:'12%',left:'6%'}}><img
                src={require("../assets/img/icon/doll-1.png")} alt="shapes"/></div>
        <div className="shape-mockup movingX d-none d-hd-block" data-bottom="9%" data-left="5%"style={{top:'9%',left:'5%'}}><img
                src={require("../assets/img/icon/shoe-1.png")} alt="shapes"/></div>
        <div className="shape-mockup shapePulse d-none d-hd-block" data-top="12%" data-right="5%" style={{top:'12%',right:'5%'}}><img
                src={require("../assets/img/icon/dog-2.png")} alt="shapes"/></div>
        <div className="shape-mockup movingX d-none d-hd-block" data-bottom="12%" data-right="3%"style={{bottom:'12%',left:'3%'}}><img
                src={require("../assets/img/icon/plane-1.png")} alt="shapes"/></div>
    </section>
    
   </>
    )
}

export default Team