import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CreateModel } from './common/Popup/createModel';
import Button from './common/Button';

const PaymentPopup = ({childName, showPaymentPopup}) => {
    const navigate = useNavigate();

    const handleSubmit = async () => {
        navigate('/contestupload')
    };
    return (
        <CreateModel
            isOpen={showPaymentPopup}
            className='mx-500'
            showCloseBtn = {false}
        >
            <div className='row justify-content-center'>
                <div className='col-xl-10'>
                    <div className='otp-form'>
                        <h3 className='title-header text-center m-0 pb-3'>
                            Pay for Contest
                        </h3>
                        <div>
                            <p>Hi <strong className='text-capitalize'>{childName !== '' ? childName : 'Child'}</strong> ! Join our summer contest for a chance to enjoy exciting activities, challenges, and win awesome prizes</p>
                            <p>Simply pay an entry fee of <strong>Rs. 500</strong> to upload your reel and get started on your journey to victory!</p>
                            <p>Gpay / Phonepe  - +91 9789-443-066</p>
                        </div>
                        <div className='form-group col-12 text-center'>
                            <Button
                                type='button'
                                id='submit-btn'
                                className='otp-submit-btn'
                                onClick={handleSubmit}
                            >
                                {'I agree'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </CreateModel>
    );
};

export default PaymentPopup;