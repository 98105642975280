import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { api } from '../../services/api';
import { Toast } from '../../services/toast';
import { childApi } from '../../services/apiVariables';
import placeholderImg from '../../assets/img/empty_image_placeholder.jpeg'
import SponserSlider from '../../components/SponserSlider';

const ParticipatedList = () => {
  const [childList, setChildList] = useState([]);

  const getChildList = async () => {
    try {
      const { getAllChildApi } = childApi;

      const response = await api({
        ...getAllChildApi,
      });

      if (response.status === 200) {
        setChildList(response.data);
      } else {

      }
    } catch (error) {
      Toast({
        type: 'error',
        message: error?.message || 'Failed to fetch data',
      });
    }
  };

  useEffect(() => {
    getChildList()
  }, []);

  return (
    <>
      <section className='event-section space'>
        <div className='container pb-4' >
          {(childList && childList.length > 0) ?
            <>
              <div class='title-area text-center wow fadeInUp mb-4' data-wow-delay='0.1s'>
                <h2 >
                  Our Contestants
                </h2>
              </div>
              <div className='row gy-70'>
                {childList.map((child) => (
                  <div
                    className='col-lg-4 col-md-6 wow fadeInUp'
                    data-wow-delay='0.1s'
                    key={child._id}
                  >
                    <div className='event-box '>
                      <div className='event-img'>
                        <img src={child.childPic || placeholderImg} alt={child.childName} />
                      </div>
                      <div className='event-info bg-smoke py-3'>
                        <h4 className='event-title'>{child?.childName}</h4>
                        <div className='event-content'>
                          <p className='event-text'>
                            Participated in <span>{child?.contests?.contestName}</span>
                          </p>
                        </div>
                        <div className='two-btns justify-content-center'>
                          <p>
                            <i className='fas fa-heart'></i>
                            <span className='vote-counter'>{child?.votersCount} Votes</span>
                          </p>
                        </div>
                        <Link to={`/vote?_id=${child._id}`} className='vote-slider-btn'>
                          Vote Now
                        </Link>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </>
            :
            <>
            </>
          }
        </div>
        <div className='participated-sponser'>
          {/* <SponserSlider /> */}
        </div>
      </section>
    </>
  );
};

export default ParticipatedList;
