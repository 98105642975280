import React, { useRef } from 'react';
import Slider from 'react-slick';

const TestimonialSlider = ({ testimonials }) => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };
  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className='testi-slider-area'>
      <Slider {...settings} ref={sliderRef}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className='row vs-carousel'>
            <div
              className='col-lg-12 col-md-12 wow fadeInUp slick-slide wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='testi-box '>
                <div className='testi-avater'>
                  <img src={testimonial.avatar} alt='testimonial' />
                  <i className='fa-solid fa-quote-left'></i>
                </div>
                <h4 className='testi-title'>{testimonial.title}</h4>
                <p className='testi-text'>{testimonial.text}</p>
                <div className='testi-author'>
                  <h5 className='name'>{testimonial.author}</h5>
                  <span className='desig'>{testimonial.designation}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className='vs-icon-box testi-1 d-none d-xl-block'>
        <button
          data-slick-prev='#testi1'
          className='icon-btn style-3 arrow-left mb-15'
          onClick={goToPrevSlide}
        >
          <i class='far fa-arrow-left' ></i>
        </button>
        <button
          data-slick-next='#testi1'
          className='icon-btn style-3 arrow-right'
          onClick={goToNextSlide}
        >
          <i class='far fa-arrow-right'></i>
        </button>
      </div>
    </div>

  );
};

export default TestimonialSlider;
