import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Button from './common/Button';
import { jwtDecode } from 'jwt-decode';
import { logout } from '../services/utilities';

const MobileMenu = ({ closeMenu, handleLoginClick }) => {
  const navigate = useNavigate();
  const userLoggedIn = localStorage.getItem('token');

  const getMobileNumber = () => {
    if (userLoggedIn) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      return decodedToken.mobileNumber;
    }
    return '';
  };

  const mobileNumber = getMobileNumber();
  const handleContestClick = () => {
    navigate('/contests');
  };
  return (
    <>
      <div className='vs-menu-wrapper vs-body-visible' onClick={closeMenu}>
        <div className='vs-menu-area'>
          <button className='vs-menu-toggle' onClick={closeMenu}>
            <i className='fal fa-times'></i>
          </button>
          <div className='mobile-logo'>
            <a href='/'>
              <img src={require('../assets/img/miracleLogo.png')} alt='logo' />
            </a>
          </div>

          <div className='vs-mobile-menu'>
            {mobileNumber && (
              <span className='PersonName'>Hi, {mobileNumber}</span>
            )}
            <ul>
              <li>
                <a href='/'>Home</a>
              </li>
              <li>
                <a href='/#about'>About</a>
              </li>
              <li>
                <a href='/#classes'>Classes</a>
              </li>
              <li>
                <a href='/#gallery'>Gallery</a>
              </li>
              <li>
                <a href='/#testimonial'>Testimonial</a>
              </li>
              <li>
                <a href='/#news'>News</a>
              </li>
              <li>
                <a href='/#contact'>Contact</a>
              </li>
              <li>
                <a href='/participants'>Contestants</a>
              </li>
              {mobileNumber && mobileNumber ? (
                <>
                  <li>
                    <div className='Menu_Item'>
                      <Link
                        to={`/contestupload`}
                        className='btn no-style p-0'
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#103741',
                        }}
                        title='Upload'
                      >
                        Join for Contest
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className='Menu_Item'>
                      <Link
                        to={`/participants`}
                        className='btn no-style p-0 '
                        title='Upload'
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#103741',
                        }}
                      >
                        Vote Now
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className='Menu_Item p-0' onClick={logout}>
                      <div
                        className='btn no-style p-0'
                        style={{
                          fontWeight: '400',
                          fontSize: '16px',
                          color: '#103741',
                        }}
                      >
                        <i className='fa fa-sign-out-alt'></i>
                        Logout
                      </div>
                    </div>
                  </li>
                </>
              ) : (
                <li>
                  <div className='mobile-login'>
                    <Button
                      className='header-register-btn'
                      onClick={handleContestClick}
                    >
                      Register
                    </Button>
                    <Button
                      className='header-login-btn'
                      onClick={handleLoginClick}
                    >
                      Login
                    </Button>
                  </div>
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
