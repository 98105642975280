import React, { useState, useEffect } from 'react';
import slide1 from '../assets/img/bg/mr_bg_3.jpeg';
import slide2 from '../assets/img/bg/mr_bg_2.jpeg';
import slide3 from '../assets/img/bg/mr_bg_1.jpeg';
import overlay from '../assets/img/bg/hero3-overlay.png';
import { Link, useNavigate } from 'react-router-dom';
import Login from '../components/AuthPopups/Login';
import Button from '../components/common/Button';
import { jwtDecode } from 'jwt-decode';

const HeroSection = () => {
  const navigate = useNavigate();
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const userLoggedIn = localStorage.getItem('token');

  const getMobileNumber = () => {
    if (userLoggedIn) {
      const token = localStorage.getItem('token');
      const decodedToken = jwtDecode(token);
      return decodedToken.mobileNumber;
    }
    return '';
  };

  const mobileNumber = getMobileNumber();
  const handleLoginClick = () => {
    setShowLoginPopup(true);
  };
  const handleVoteClick = () => {
    navigate('/participants');
  };
  const slides = [
    {
      bgSrc: slide1,
      overlaySrc: overlay,
      subtitle: 'We, Miracle is the Perfect Balance of',
      title: 'Care and Education',
    },
    {
      bgSrc: slide2,
      overlaySrc: overlay,
      subtitle: 'We Love',
      title: 'Caring For your Love!!',
    },
    {
      bgSrc: slide3,
      overlaySrc: overlay,
      subtitle: 'Our Fun and',
      title: 'Caring Environment',
    },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <section className='vs-hero-wrapper hero-3 position-relative'>
      <div className='section-after style-2 d-none d-md-block'>
        <img src={require('../assets/img/shape/hero3-after.png')} alt='shape' />
      </div>
      <div
        className='hero-slider3 vs-carousel'
        data-slide-show='1'
        data-md-slide-show='1'
        data-arrows='true'
        data-fade='true'
        style={{ display: 'flex' }}
      >
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`vs-hero-inner ${
              index === currentSlide ? 'active' : ''
            }`}
            style={{
              flex: '0 0 100%',
              display: 'flex',
              transform: `translateX(-${currentSlide * 100}%)`,
              transition: 'transform 0.5s ease',
            }}
          >
            <div
              className='vs-hero-bg'
              style={{
                backgroundImage: `url(${slide.bgSrc})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
              }}
            >
              <img src={slide.overlaySrc} alt='overlay' />
            </div>
            <div className='hero-content3'>
              <span
                className='h4 hero-subtitle style-3'
                data-ani='slideinup'
                data-ani-delay='0s'
              >
                {slide.subtitle}
              </span>
              <h2
                className='hero-title style-2'
                data-ani='slideinup'
                data-ani-delay='0.2s'
              >
                {slide.title}
              </h2>
              {userLoggedIn ? (
                <Button className='vs-btn wave-btn' onClick={handleVoteClick}>
                  Vote Now
                </Button>
              ) : (
                <Button className='vs-btn wave-btn' onClick={handleLoginClick}>
                  Login for Contest
                </Button>
              )}
            </div>
          </div>
        ))}
      </div>
      <div
        class='shape-mockup rotate d-none d-md-block'
        data-top='10%'
        data-left='11%'
        style={{ top: '10%', left: '11%' }}
      >
        <img src={require('../assets/img/icon/sun-4.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup movingBottomLeft'
        data-bottom='20%'
        data-left='0%'
        style={{ bottom: '20%', left: '0%' }}
      >
        <img src={require('../assets/img/icon/car-5.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup movingTopLeft d-none d-md-block'
        data-bottom='3%'
        data-right='3%'
        style={{ bottom: '3%', right: '3%' }}
      >
        <img src={require('../assets/img/icon/rain-2.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup moving d-none d-xl-block'
        data-top='9%'
        data-right='8%'
        style={{ top: '9%', right: '8%' }}
      >
        <img src={require('../assets/img/icon/m.png')} alt='shapes' />
      </div>
      {showLoginPopup && (
        <Login
          onShowLoginPopup={setShowLoginPopup}
          showLoginPopup={showLoginPopup}
          handleLoginClick={handleLoginClick}
        />
      )}
    </section>
  );
};

export default HeroSection;
