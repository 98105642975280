import vision from "../assets/img/vision.jpeg"
import values from "../assets/img/values.png"
import mission from "../assets/img/mission.jpeg"
import serviceline from "../assets/img/shape/service-line.png"
import lolypop from "../assets/img/icon/lolypop.png"
import starsmall from "../assets/img/icon/star-sm.png"
import star from "../assets/img/icon/star.png";



const Service = () => {
    return(
        <section className="service-section space">
        <div className="container">
            <div className="row gy-30">
                <div className="col-lg-4 service-card wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-card-inner">
                        <h2 className="sr-title h4"><a href="/">Our Vision</a></h2>
                        <div className="sr-icon">
                            <img src={vision} alt=""/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 service-card wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-card-inner">
                        <h2 className="sr-title h4"><a href="/">Our Values</a></h2>
                        <div className="sr-icon">
                            <img src={values} alt="icon"/>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 service-card wow fadeInUp" data-wow-delay="0.1s">
                    <div className="service-card-inner">
                        <h2 className="sr-title h4"><a href="/">Our Mission</a></h2>
                        <div className="sr-icon">
                            <img src={mission} alt="icon"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="shape-mockup z-index-3 d-none d-xl-block" style={{ top: '-4%', left: '4%' }}><img
                src={serviceline} alt="shapes"/></div>
        <div className="shape-mockup shapePulse z-index-negative d-none d-xl-block" style={{ top: '27%', left: '9%' }}><img
                src={lolypop} alt="shapes"/></div>
        <div className="shape-mockup shapePulse rotate z-index-negative" style={{ top: '19%', right: '21%' }}><img
                src={starsmall} alt="shapes"/></div>
        <div className="shape-mockup shapePulse rotate z-index-negative"   style={{ bottom: '20%', right: '10%' }}><img
                src={star} alt="shapes"/></div>
    </section>
    )
}

export default Service;