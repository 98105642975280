

const Work = () => {
   return(
    <section className="process-section space-bottom-shape2-plus">
    <div className="container">
        <div className="row mb-20 text-center justify-content-center wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-xl-6 col-lg-7 col-md-8 col-sm-9">
                <div className="title-area">
                    <span className="sub-title">Work Process</span>
                    <h2 className="sec-title">How We Manage Our Kids Education</h2>
                </div>
            </div>
        </div>
        <div className="process-area">
            <div className="row gy-60 justify-content-between">
                <div className="col-auto process-box wow fadeInUp" data-wow-delay="0.1s">
                    <div className="process-box-body">
                        <span className="process-number">01</span>
                        <div className="process-content">
                            <div className="process-icon">
                                <img src={require ("../assets/img/icon/process2-1.png") }alt="icon"/>
                            </div>
                            <h5 className="process-name"><a href="/">Find A Service Now</a></h5>
                        </div>
                    </div>
                </div>
                <div className="col-auto process-box wow fadeInUp" data-wow-delay="0.1s">
                    <div className="process-box-body">
                        <span className="process-number">02</span>
                        <div className="process-content">
                            <div className="process-icon">
                                <img src={require("../assets/img/icon/process2-2.png")} alt="icon"/>
                            </div>
                            <h5 className="process-name"><a href="/">Appointment With Us</a></h5>
                        </div>
                    </div>
                </div>
                <div className="col-auto process-box wow fadeInUp" data-wow-delay="0.1s">
                    <div className="process-box-body">
                        <span className="process-number">03</span>
                        <div className="process-content">
                            <div className="process-icon">
                                <img src={require("../assets/img/icon/process2-3.png")} alt="icon"/>
                            </div>
                            <h5 className="process-name"><a href="/">Start Learning Your Kids</a></h5>
                        </div>
                    </div>
                </div>
                <div className="col-auto process-box wow fadeInUp" data-wow-delay="0.1s">
                    <div className="process-box-body">
                        <span className="process-number">04</span>
                        <div className="process-content">
                            <div className="process-icon">
                                <img src={require("../assets/img/icon/process2-4.png")} alt="icon"/>
                            </div>
                            <h5 className="process-name"><a href="/">Get The Establish Kids</a></h5>
                        </div>
                    </div>
                </div>
            </div>
            <div className="process-line"><img src={require("../assets/img/shape/dashed-line-1.png")} alt="shape"/></div>
        </div>
    </div>
</section>
   )
}

export default Work