import React, { useState, useEffect } from 'react';
import { Link,useNavigate } from 'react-router-dom';
import logo from '../assets/img/miracleLogo.png';
import MobileMenu from './MobileMenu';
import Login from './AuthPopups/Login';
import Button from './common/Button';
import { jwtDecode } from 'jwt-decode';
import { ProfileDropdown } from './common/Dropdowns/ProfileDropdown';
import PaymentPopup from './PaymentPopup';

const Header = () => {
  const navigate = useNavigate();
  const [showPaymentPopup, togglePaymentPopup] = useState(false);
  const [showLoginPopup, setShowLoginPopup] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 0) {
        setIsActive(true);
      } else {
        setIsActive(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };
  const handleLoginClick = () => {
    setShowLoginPopup(true);
  };

  const CloseMenu = () => {
    setIsMenuOpen(false);
  };

  const ValidateUser = () => {
    const token = localStorage.getItem('token');

    let mobileNumber = '';
    if (token) {
      const decodedToken = jwtDecode(token);
      mobileNumber = decodedToken.mobileNumber;
    }

    return (
      <>
        {token ? (
          <>
            <ProfileDropdown mobileNumber={mobileNumber} />
          </>
        ) : (
          <div className='header-button'>
            <Button className='header-register-btn' onClick={handleContestClick}>
              Register
            </Button>
            <Button className='header-login-btn' onClick={handleLoginClick}>
              Login
            </Button>
          </div>
        )}
      </>
    );
  };

  const handleContestClick = () =>{
    navigate('/contests')
  }
  return (
    <>
      <header className='vs-header'>
        <div className='header-top-area d-none d-lg-block'>
          <div className='container'>
            <div className='row align-items-center justify-content-between'>
              <div className='col-auto'>
                <div className='header-links'>
                  <ul>
                    <li>
                      <i className='fal fa-clock'></i>08.30 am - 07.30 pm - All
                      Seven Days
                    </li>
                    <li>
                      <i className='fal fa-phone'></i>
                      <a href='tel:+918122947900'>+91 81229 47900</a>
                    </li>
                    <li>
                      <a href='#address'>
                        <i className='fal fa-map-marker-alt'></i>Location
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className='col-auto'>
                <div className='header-social'>
                  <ul>
                    <li>
                      <a
                        href='https://www.facebook.com/profile.php?id=100091610798322&mibextid=ZbWKwL'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-facebook-f'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://instagram.com/miracle_nursery_daycare'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-instagram'></i>
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://g.co/kgs/hWK6We'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        <i className='fab fa-google'></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='sticky-wrapper'>
          <div className={`sticky-active ${isActive ? 'active' : ''}`}>
            <div className='header-menu-area'>
              <div className='container position-relative'>
                <div className='row gx-20 align-items-center justify-content-between'>
                  <div className='col-auto'>
                    <div className='header-logo'>
                      <a href='/'>
                        <img src={logo} alt='logo' />
                      </a>
                    </div>
                  </div>
                  <div className='col-auto'>
                    <nav className='main-menu menu-style1 d-none d-lg-inline-block'>
                      <ul>
                        <li>
                          <a href='/'>Home</a>
                        </li>
                        <li>
                          <a href='/#about'>About</a>
                        </li>
                        <li>
                          <a href='/#class'>classes</a>
                        </li>
                        <li>
                          <a href='/#gallery'>Gallery</a>
                        </li>
                        <li>
                          <a href='/#testimonial'>Testimonial</a>
                        </li>
                        <li>
                          <a href='/#news'>News</a>
                        </li>
                        <li>
                          <a href='/#contact'>Contact</a>
                        </li>
                        <li>
                          <a href='/participants'>Contestants</a>
                        </li>
                      </ul>
                    </nav>
                    <button
                      type='button'
                      className='vs-menu-toggle d-block d-lg-none'
                      onClick={toggleMenu}
                    >
                      <i className='far fa-bars'></i> Menu
                    </button>
                  </div>
                  <div className='col-auto d-none d-xl-block'>
                    {/* <div className='header-button'>
                      <Button
                        className='vs-btn wave-btn'
                        onClick={handleLoginClick}
                      >
                        Login for Contest
                      </Button>
                    </div> */}
                    {ValidateUser()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      {isMenuOpen && (
        <MobileMenu closeMenu={CloseMenu} handleLoginClick={handleLoginClick} />
      )}
      {showLoginPopup && (
        <Login
          onShowLoginPopup={setShowLoginPopup}
          showLoginPopup={showLoginPopup}
          handleLoginClick={handleLoginClick}
        />
      )}
      <PaymentPopup
          showPaymentPopup = {showPaymentPopup}
          togglePaymentPopup={togglePaymentPopup}
      />
    </>
  );
};

export default Header;
