import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes,useLocation } from 'react-router-dom';
import Header from './components/header';
import Home from './pages/home';
import Preloader from './components/common/Preloader';
import Footer from './components/Footer';
import ImageUpload from './pages/ImageUpload/ImageUpload';
import Vote from './pages/Vote/Vote';
import ParticipatedList from './pages/participatedList/ParticipatedList';
import ContestList from './pages/Contest/ContestsList';
import RegisterForm from './pages/Contest/RegisterForm';


function App() {
  const [isLoading, setIsLoading] = useState(true);
 

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router >
      <>
      <ScrollToTopOnMount />
        {isLoading ? (
          <Preloader />
        ) : (
          <>
            <Header />
            <Routes>
              <Route path="/" exact={true} element={<Home />} />
              <Route path="/participants" exact={true} element={<ParticipatedList />} />
              <Route path='/contestupload' exact={true} element={<ImageUpload/>} />
              <Route path='/vote' exact={true} element={<Vote/>}/>
              <Route path='/contests' exact={true} element={<ContestList/>}/>
              <Route path='/register' exact={true} element={<RegisterForm/>}/>
            </Routes>
            <Footer/>
          </>
        )}
      </>
    </Router>
  );
}

export default App;

function ScrollToTopOnMount() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0); 
  }, [pathname]);

  return null;
}
