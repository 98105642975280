import EnquiryForm from "../components/EnquiryForm/EnquiryForm"


const FormSection = () =>{
    return(
<>
<section className="contact-section space-page" id="contact">
        <div className="container">
            <div className="row gx-50 gy-30 align-items-center">
                <div className="col-lg-5 wow fadeInRight" data-wow-delay="0.1s">
                    <div className="text-center text-lg-start">
                        <span className="sub-title">Make Appointment</span>
                        <h2 className="sec-title big-title">Join Our Fun with Cutie Kids</h2>
                        <p className="fs-md mb-30">Formulate innovative web-readiness and installed base ideas.
                            Distinctively integrate high-payoff paradigms without next generation systems disseminate
                            holistic e-services through customer directed expertise.</p>
                    </div>
                </div>
                <div className="col-lg-7 wow fadeInLeft" data-wow-delay="0.1s">
                   <EnquiryForm/>
                </div>

            </div>
        </div>
    </section>
</>
    )
}

export default FormSection