import navone from '../assets/img/service/s3-1.jpg';
import navoneicon from '../assets/img/icon/s3-1.png';
import tabicon from '../assets/img/icon/sr3-1.png'
import navtwo from '../assets/img/service/s3-4.jpg';
import navtwoicon from '../assets/img/icon/s3-2.png';
import tabicon2 from '../assets/img/icon/sr3-2.png'
import navthree from '../assets/img/service/s3-6.jpg';
import navthreeicon from '../assets/img/icon/s3-3.png';
import tabicon3 from '../assets/img/icon/sr3-3.png'
import navfour from '../assets/img/service/s3-4.jpg';
import navfouricon from '../assets/img/icon/s3-4.png';
import tabicon4 from '../assets/img/icon/sr3-4.png'
import navfive from '../assets/img/service/s3-6.jpg';
import navfiveicon from '../assets/img/icon/s3-5.png';
import tabicon5 from '../assets/img/icon/sr3-5.png'
import navsix from '../assets/img/service/s3-6.jpg';
import navsixicon from '../assets/img/icon/s3-6.png';
import tabicon6 from '../assets/img/icon/sr3-6.png'
 
 
 const tabsData = [
    {
      id: 'nav-one',
      tabtitle: '"i" - Inquiry',
      tabicon: navoneicon,
      title: 'The "i" in iSTEAM',
      image: navone,
      icon: tabicon,
      description: 'Inquiry Learning is an innovative teaching philosophy which promotes 21st century learning. Unlike the traditional approach, which underestimated the potential of young children as thinkers and focused on rote learning and memorization.',
      points: [
        'Inquiry Learning prompts children to ask questions',
        'Explore in-depth projects together',
      ],
      researchText:
        'Research has shown that when young children are in an environment where they are encouraged to collaborate, investigate, and explore, they are equipped with the tools to become lifelong learners.',

    },
    {
      id: 'nav-two',
      tabtitle: '"S" is for Science',
      tabicon: navtwoicon,
      title: '"S" is for Science',
      image: navtwo,
      icon: tabicon2,
      description: 'Miracle Nursery School students engage in scientific discovery every day !!',
      points: [
        'Our young scientists develop observation skills as they  perform simple experiments',
        'Make predictions, ask “what if?”, and explore the natural world',
        'From cause and effect to the laws of gravity, we are testing the limits of how our world works.'
      ],
      
    },
    {
      id: 'nav-three',
      tabtitle: '"T" is for Technology',
      tabicon: navthreeicon,
      title: '"T" is for Technology',
      image: navtwo,
      icon: tabicon2,
      description: 'While we may use computers and tablets to research and explore, technology for young children is so much more...',
      points: [
        'We discover the simple machines and tools that are all around us',
        'We utilize technology to advance our thinking as we measure with scales and rulers, or experiment with latches and ramps',
        'Technology is everywhere!'
      ],
    },
    {
      id: 'nav-four',
      tabtitle: '"E" Is For Engineering',
      tabicon: navfouricon,
      title: '"E" Is For Engineering',
      image: navone,
      icon: tabicon4,
      description: 'When you see a block tower constructed, you are witnessing a young engineer in action !!',
      points: [
        'Through problem solving, constructing, creating and putting things together, our students become engineers as they figure out how things work',
        'They have the opportunity to explore gravity, balance, symmetry, and mathematical concepts through the rich materials and experiences we provide'
      ],
    },
    {
      id: 'nav-five',
      tabtitle: '“A” Is For Art',
      tabicon: navfiveicon,
      title: '“A” Is For Art',
      image: navfive,
      icon: tabicon5,
      description: 'We put the “creative” back into fine arts. Miracle Nusery School students have enriched opportunities to represent',
      points: [
        'Ideas, Feelings, and Knowledge through music, drama, art, and dance experiences',
        'Our monthly study of great authors and artists expands students’ understanding and appreciation of a variety of artistic expression through multi-sensory activities Mand projects'
      ],
    },
    {
      id: 'nav-six',
      tabtitle: '"M" Is For Math',
      tabicon: navsixicon,
      title: '"M" Is For Math',
      image: navsix,
      icon: tabicon6,
      description: 'Mathematical thinking at Miracle Nursery School is more than counting and knowing your numbers...',
      points: [
        'We encourage our students to use mathematical knowledge as they predict, problem solve, identify patterns, and compare and contrast objects',
        'From learning shapes to exploring volume and size, mathematics is an important tool for understanding our world.'
      ],
    },
  ];

  export default tabsData;


