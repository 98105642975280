import React, { useState, useEffect } from 'react';


import Service from './Service';
import About from './About'
import ServiceTabs from './Isteam';
import AboutSection from './AboutSection';
import Curriculum from './Curriculum';
import Work from './work';
import Gallery from './Gallery';
import Testimonial from './Testimonial';
import Team from './Team';
import Blog from './Blog';
import FormSection from './FormSection';
import Contact from './Contact';
import HeroSection from './Hero';
import ParticipantSlider from '../components/ParticipantSlider'
import SponserSlider from '../components/SponserSlider';
import { api } from '../services/api';
import { childApi } from '../services/apiVariables';

const Home = () => {
  useEffect(()  => {
    const verifyChild = async () => {
      try {
        const { getUser } = childApi;
  
        const response = await api({
          ...getUser
        });
        if (response.status === 200) {
        } else {
        }
      } catch (error) {
        if(error &&  ( error.status === 404 || error.status === 401 ) ){
          localStorage.clear()
        }
      }
    };
    const token  = localStorage.getItem('token')
    if(token){
      verifyChild()
    }
  }, [])

  return (
    <>
      <HeroSection />
      <ParticipantSlider/>
      {/* <SponserSlider/> */}
      <About />
      <Service />
      <ServiceTabs />
      <AboutSection />
      <Curriculum />
      <Work />
      <Gallery />
      <Testimonial />
      <Team />
      <Blog />
      <FormSection />
      <Contact />
    </>
  );
};

export default Home;
