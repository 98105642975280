const Footer = () => {
  return (
    <footer className='footer-wrapper footer-layout2 shape-mockup-wrap'>
      <div className='shape-before'>
        <img
          src={require('../assets/img/shape/footer-before.png')}
          alt='shape'
        />
      </div>
      <div
        className='shape-mockup movingX d-none d-hd-block'
        data-bottom='37%'
        data-left='6%'
        style={{ bottom: '37%', left: '6%' }}
      >
        <img src={require('../assets/img/icon/cloud-2.png')} alt='shapes' />
      </div>

      <div className='widget-area'>
        <div className='container'>
          <div className='row justify-content-between'>
            <div
              className='col-xl-auto col-md-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='widget footer-widget'>
                <div className='vs-widget-about'>
                  <div className='about-logo'>
                    <a href='/'>
                      <img
                        src={require('../assets/img/footer-logo.png')}
                        alt='Miracle kids'
                      />
                    </a>
                  </div>
                  <p className='about-text'>
                    Creating a world of wonder as we learn together…….inspiring
                    curiosity with joy, love and laughter.
                  </p>
                  <div className='multi-social'>
                    <a
                      href='https://www.facebook.com/profile.php?id=100091610798322&mibextid=ZbWKwL'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-facebook-f'></i>
                    </a>
                    <a
                      href='https://instagram.com/miracle_nursery_daycare'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-instagram'></i>
                    </a>
                    <a
                      href='https://g.co/kgs/hWK6We'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <i className='fab fa-google'></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-xl-auto col-md-6  col-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='widget footer-widget widget_nav_menu'>
                <h4 className='widget_title'>Details Info</h4>
                <ul className='menu'>
                  <li>
                    <a href='/#home'>Home</a>
                  </li>
                  <li>
                    <a href='/#about'>About</a>
                  </li>
                  <li>
                    <a href='/#class'>classes</a>
                  </li>
                  <li>
                    <a href='/#gallery'>Gallery</a>
                  </li>
                  <li>
                    <a href='/#contact'>Contact</a>
                  </li>
                </ul>
              </div>
            </div>
            <div
              className='col-xl-auto col-md-6 col-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='widget footer-widget'>
                <h4 className='widget_title'>News Feed</h4>
                <div className='recent-post-wrap'>
                  <div className='recent-post'>
                    <div className='media-body'>
                      <h4 className='post-title'>
                        <a href='/'>Feeding</a>
                      </h4>
                    </div>
                  </div>
                  <div className='recent-post'>
                    <div className='media-body'>
                      <h4 className='post-title'>
                        <a href='/'>Playing</a>
                      </h4>
                    </div>
                  </div>
                  <div className='recent-post'>
                    <div className='media-body'>
                      <h4 className='post-title'>
                        <a href='/'>Educating</a>
                      </h4>
                    </div>
                  </div>
                  <div className='recent-post'>
                    <div className='media-body'>
                      <h4 className='post-title'>
                        <a href='/'>Nap Time</a>
                      </h4>
                    </div>
                  </div>
                  <div className='recent-post'>
                    <div className='media-body'>
                      <h4 className='post-title'>
                        <a href='/'>Bath Time</a>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className='col-xl-auto col-md-6 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='widget footer-widget'>
                <h3 className='widget_title'>Contact Us</h3>
                <div className='vs-widget-contact'>
                  <p className='footer-info'>
                    <i className='fas fa-map-marker-alt'></i>No.15, 23rd East
                    cross road, Gandhi Nagar, Vellore - 632006.
                  </p>
                  <p className='footer-info'>
                    <i className='fas fa-envelope'></i>
                    <a href='mailto:miracleschoolvellore@gmail.com'>
                      miracleschoolvellore@gmail.com
                    </a>
                  </p>
                  <p className='footer-info'>
                    <i className='fas fa-phone-alt'></i>
                    <a href='tel:+918122947900'>+91 81229 47900</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='footer-copyright'>
        <div className='container'>
          <p className='copyright'>
            &copy; Copyright 2023 Iconic Global Solution. All Rights Reserved by
             <a
              href='https://iconicglobalsolution.com/'
              className='color-primary'
              target='_blank'
            >
              Iconic Global Solution
            </a>
          </p>
        </div>
      </div>

      <div
        className='shape-mockup rotate d-none d-hd-block'
        data-top='69%'
        data-left='32%'
      >
        <img src={require('../assets/img/icon/f-i-3-3.png')} alt='shapes' />
      </div>
      <div
        className='shape-mockup movingX d-none d-hd-block'
        data-top='3%'
        data-left='46%'
      >
        <img src={require('../assets/img/icon/f-i-3-4.png')} alt='shapes' />
      </div>
      <div
        className='shape-mockup movingX-reverse d-none d-hd-block'
        data-top='18%'
        data-right='3%'
      >
        <img src={require('../assets/img/icon/f-i-3-5.png')} alt='shapes' />
      </div>
      <div
        className='shape-mockup movingX d-none d-hd-block'
        data-top='62%'
        data-right='6%'
      >
        <img src={require('../assets/img/icon/f-i-3-6.png')} alt='shapes' />
      </div>
    </footer>
  );
};

export default Footer;
