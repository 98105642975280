import AboutSlider from "../components/AboutSlider";
import star from '../assets/img/icon/star.png'
import plane from '../assets/img/icon/cap-plane.png'
import ball from '../assets/img/icon/busket-ball.png'


const AboutSection = () =>{
    return(
        <section className="about-section bg-smoke space">
        <div className="container">
          <div className="row flex-column-reverse flex-lg-row align-items-center gy-30">
            <div className="col-lg-5 wow fadeInUp" data-wow-delay="0.1s">
              <div className="text-center text-lg-start">
                <span className="sub-title">More About Us</span>
                <h2 className="sec-title big-title">To Create future stars</h2>
                <p className="fs-md mb-30">
                  We discover the Miracle in our Creative School:
                  A unique learning environment powered with STEAM learning.
                  Come experience the wonder and excitement of this School as children interact, investigate,
                  and explore.
                </p>
              </div>
            </div>
            <div className="col-lg-7">
             <AboutSlider/>
            </div>
          </div>
        </div>
        <div className="shape-mockup shapePulse d-none d-lg-block rotate" style={{bottom:'22%',right:'5%'}}><img
                src={star} alt="shapes"/></div>
        <div className="shape-mockup shapePulse d-none d-hd-block"  style={{top:'17%',left:'6%'}}><img
                src={plane} alt="shapes"/></div>
        <div className="shape-mockup rotate d-none d-sm-block z-index-2"  style={{bottom:'-7%',left:'6%'}}><img
                src={ball} alt="shapes"/></div>
      </section>
  
    )
}

export default AboutSection;