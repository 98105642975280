import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const TeamSlider = ({ teamMembers }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <Slider {...settings}>
      {teamMembers.map((member, index) => (
        <div key={index} className='row vs-carousel'>
          <div className='col-lg-12'>
            <div className='team-card'>
              <div className='team-img'>
                <img src={member.image} alt='team' />
              </div>
              <div className='team-info'>
                {/* <h4 className='team-title'>{member.name}</h4>
                <span className='team-desig'>{member.designation}</span> */}
              </div>
            </div>
          </div>
        </div>
      ))}
    </Slider>
  );
};

export default TeamSlider;
