const Contact = () => {
  return (
    <>
    <section class='contact-section contact-spacing' id='address'>
      <div class='container'>
        <div class='contact-info-box'>
          <div class='row gx-50 gy-30'>
            <div class='col-lg-6 wow fadeInRight' data-wow-delay='0.1s'>
              <h2 class='contact-title'>Katpadi Branch</h2>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/location.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>School Address:</h4>
                  <p class='info-text'>
                    No.15, 23<sup>rd</sup> East cross road, Kangeyanallur Main
                    road,
                    <br />
                    Gandhi Nagar, Vellore - 632006.
                  </p>
                </div>
              </div>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/phone.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>Call Us For Help:</h4>
                  <p class='info-text'>
                    <a href='tel:+918122947900'>+91 81229 47900</a>
                  </p>
                </div>
              </div>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/email.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>Mail info:</h4>
                  <p class='info-text'>
                    <a href='mailto:miracleschoolvellore@gmail.com'>
                      miracleschoolvellore@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div class='contact-location'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d360.00351507244676!2d79.14604904104714!3d12.952801788016163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bad47fa279643cb%3A0xb73711101fe1a2b0!2sMiracle%20Nursery%20School%20and%20Daycare!5e0!3m2!1sen!2sin!4v1683187778189!5m2!1sen!2sin'
                  width='600'
                  height='450'
                  style={{ border: 0 }}
                  allowFullScreen=''
                  loading='lazy'
                  title='Google Maps'
                ></iframe>
              </div>
            </div>
            <div class='col-lg-6 wow fadeInRight' data-wow-delay='0.1s'>
              <h2 class='contact-title'>Sathuvachari Branch</h2>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/location.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>School Address:</h4>
                  <p class='info-text'>
                    No.17/494, Third East Coast Rd, Phase 2, Sathuvachari,
                    <br />
                    Vellore, Tamil Nadu 632009
                  </p>
                </div>
              </div>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/phone.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>Call Us For Help:</h4>
                  <p class='info-text'>
                    <a href='tel:+918122947900'>+91 81229 47900</a>
                  </p>
                </div>
              </div>
              <div class='info-media'>
                <div class='media-icon'>
                  <img src={require('../assets/img/icon/email.png')} alt='icon' />
                </div>
                <div class='media-body'>
                  <h4 class='info-title'>Mail info:</h4>
                  <p class='info-text'>
                    <a href='mailto:miracleschoolvellore@gmail.com'>
                      miracleschoolvellore@gmail.com
                    </a>
                  </p>
                </div>
              </div>
              <div class='contact-location'>
                <iframe
                  src='https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15553.856249996594!2d79.1410276!3d12.9421299!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a55594a5492ac87%3A0x7a8d5aa4b14879ae!2sMIRACLE%20DAYCARE%20SATHUVACHARI!5e0!3m2!1sen!2sin!4v1699877098603!5m2!1sen!2sin'
                  style={{ border: '0' }}
                  allowFullScreen=''
                  loading='lazy'
                  title='Google Maps Embed'
                  width='600'
                  height='450'
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section className="mockup1" >
        <div className="container">
            <div className="row justify-content-center">
                <div className="col-lg-9 text-center wow fadeInUp" data-wow-delay="0.1s">
                    <img className="mb-70" src={require("../assets/img/icon/mail-big-icon.png")} alt="icon"/>
                    <h2 className="h1">Enrollment Is Now Going On</h2>
                    <p className="mb-35">Call To Enroll Your Child - +91 81229 47900</p>
                    <a href="#contact" className="vs-btn wave-btn">Contact Us</a>
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default Contact;
