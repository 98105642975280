import BlogSlider from '../components/BlogSlider';
import slide1 from '../assets/img/news/2.jpeg';
import slide2 from '../assets/img/news/3.jpeg';
import slide3 from '../assets/img/news/4.jpeg';
import slide4 from '../assets/img/news/5.jpeg';

const Blog = () => {
    const teamMembersData = [
        {
          image: slide1,
          title: 'After School Program for LKG to Grade 9',
        },
        {
          image: slide2,
          title: 'Daycare Services From 14 Months to 10 Years',
        },
        {
            image: slide3,
            title: 'Admission Open For Daycare Service',
          },
          {
            image: slide4,
            title: 'Summer Health Tips for Kids',
          },
      ];
  return (
    <>
      {/* blog */}
      <section className='blog-section bg-smoke space-shape-min' id='news'>
        <div className='container'>
          <div
            className='row text-center justify-content-center wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <div className='col-xl-6 col-lg-7 col-md-8 col-sm-9'>
              <div className='title-area'>
                <span className='sub-title'>Latest News</span>
              </div>
            </div>
          </div>
          <BlogSlider teamMembers={teamMembersData} />
        </div>
        <div className='shape-before'>
          <img src={require('../assets/img/shape/before-shape-4.png')} alt='shape' />
        </div>
        <div className='shape-after'>
          <img src={require('../assets/img/shape/after-shape-4.png')} alt='shape' />
        </div>
        <div
          className='shape-mockup rotate d-none d-md-block'
          data-top='14%'
          data-left='4%'
          style={{top:'14%',left:'4%'}}
        >
          <img src={require('../assets/img/icon/star-6.png')} alt='shapes' />
        </div>
        <div
          className='shape-mockup shapePulse d-none d-hd-block'
          data-bottom='14%'
          data-left='9%'
          style={{top:'14%',left:'9%'}}
        >
          <img src={require('../assets/img/icon/flower-1.png')} alt='shapes' />
        </div>
        <div
          className='shape-mockup moving d-none d-xl-block'
          data-top='17%'
          data-right='14%'
          style={{top:'17%',right:'14%'}}
        >
          <img src={require('../assets/img/icon/leaves-2.png')} alt='shapes' />
        </div>
        <div
          className='shape-mockup movingX d-none d-hd-block'
          data-bottom='11%'
          data-right='6%'
          style={{bottom:'11%',left:'6%'}}
        >
          <img src={require('../assets/img/icon/animal-2.png')} alt='shapes' />
        </div>
      </section>
    </>
  );
};

export default Blog;
