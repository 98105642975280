import TestimonialSlider from "../components/TestimonialSlider";
import testi1 from '../assets/img/testimonials/user1.png'
import testi2 from '../assets/img/testimonials/user2.png'
import testi3 from '../assets/img/testimonials/user3.png'


const Testimonial = () => {
  const testimonialsData = [
    {
      avatar: testi1,
      title: 'They Are Amazing',
      text: `The name says it all..yes it's definitely a miracle. My 14 months old who was handled all these days right from his birth only by me, never settles with anyone other than me. When I came to a situation where I had to seek the help of a day care, but anxious about him being clingy to me alone, just prayed... a miracle happened. After a few days of crying, now settled happily in the daycare. I am very much pleased with the management and staff. They are very caring and concerning. They are taking care of my baby as their own. That's what I prayed for. Thanks to the team. I recommend this daycare center highly to every working people. This is my honest review.`,
      author: 'Berry Priscy',
      designation: 'Parent',
    },
    {
      avatar: testi2,
      title: 'They Are Like Family',
      text: `Such a wonderful kindergarten it was🌈❤️😌education plus entertainment all there🤗😌. I personally understand that' 💯❤️. I am seeing a lot of miracles here👀😍🦋🪄. I wish to go to a high position🙌💯😌 with god blessings❤️💯😌🤗. Mine future star is finely built here💗 …`,
      author: 'Gayathri M',
      designation: 'Parent',
    },
    {
      avatar: testi3,
      title: 'My Child Enjoyed',
      text: `I would like to express my heartfelt appreciation to Mrs.Nirosha Aravind for the
          wonderful nursery school and daycare that she has begun.
          This school Miracle nursery is nurturing & guiding my child's early learning
          journey in a wonderful way.
          The dedicated teachers and caring staff at Miracle school have created a warm n
          welcoming environment where children can flourish.
          The engaging activities, age-appropriate curriculum and stimulating learning
          materials have sparked my child's curiosity and love for learning.
          I am truly grateful for the school which is an invaluable role in my child's
          early years.`,
      author: 'Priya Dharshini',
      designation: 'Parent',
    },
  ];
  return (
    <section className="testimonial-section space-top-shape2-plus space-bottom-shape-plus" id="testimonial">
      <div className="container">
        <div className="row gy-30 align-items-center">
          <div className="col-lg-4 wow fadeInUp" data-wow-delay="0.1s">
            <div className="text-center text-lg-start">
              <span className="sub-title">Parent Testimonial</span>
              <h2 className="sec-title">Positive Parents Review Of Us</h2>
              <a href="https://www.google.com/maps/place/Miracle+Nursery+School+and+Daycare/@12.9525857,79.1436086,17z/data=!4m8!3m7!1s0x3bad47fa279643cb:0xb73711101fe1a2b0!8m2!3d12.9525857!4d79.1461835!9m1!1b1!16s%2Fg%2F11ty8fldz0?hl=en-US&entry=ttu"
                className="vs-btn wave-btn style-1" target="_blank">View All Reviews</a>
            </div>
          </div>
          <div className="col-lg-8">
            <TestimonialSlider testimonials={testimonialsData} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Testimonial