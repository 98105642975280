import React, { useState } from 'react';
import { useParams, useLocation,useNavigate } from 'react-router-dom';
import bg from '../../assets/img/bg/breadcrumb-bg-smoke.jpg';
import FormField from '../../components/common/FormField';
import validate from 'validate.js';
import { api } from '../../services/api';
import { registerApi } from '../../services/apiVariables';
import { Toast } from '../../services/toast';
import FormInput from '../../components/common/FormInput';
import PaymentPopup from '../../components/PaymentPopup';

const RegisterForm = () => {
  const navigate = useNavigate()
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const name = searchParams.get('name');
  const contestId = searchParams.get('_id');
  const [errors, setErrors] = useState({});
  const [showPaymentPopup, togglePaymentPopup] = useState(false);

  const initialFormValues = {
    childName: '',
    dateOfBirth: '',
    fatherName: '',
    motherName: '',
    mobileNumber: '',
    schoolName: '',
  };
  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const resetForm = () => {
    setFormValues({ ...initialFormValues });
  };
  const handleInputChange = (name, value) => {
    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors({
      ...errors,
      [name]: '',
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const { registerFormApi } = registerApi;
    const validationResult = validate(formValues, validationRules);

    if (validationResult) {
      setErrors(validationResult);
    } else {
      try {
        const response = await api({
          ...registerFormApi,
          body: {
            ...formValues,
            contestId: contestId,
          },
         
        });
        if (response.status === 200) {
          const { token } = response.data;
          localStorage.setItem('token', token);
          Toast({
            type: 'success',
            message: 'Successfully Registered for contest',
          });
          resetForm();
          togglePaymentPopup(true)
          // navigate('/contestupload')
        } else {
          Toast({
            type: 'error',
            message: response?.error || 'Try again or call admin',
          });
        }
      } catch (error) {
        console.error('API Error:', error);
        Toast({
          type: 'error',
          message: error?.error || 'Try again or call admin',
        });
      }
    }
  };

  return (
    <>
      <section className='event-section bg-smoke space'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-12 col-12'>
              <div className='contact-form-box'>
                <h2 className='contact-title'>Register for {name}</h2>
                
                <form className='contact-form ' onSubmit={handleSubmit}>
                  <div className='row'>
                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        type='text'
                        placeholder='Child Name'
                        icon='fa-user'
                        name='childName'
                        label='Child Name'
                        value={formValues.childName}
                        onChange={handleInputChange}
                        error={errors['childName']}
                      />
                    </div>
                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        type='date'
                        placeholder='choose date'
                        icon='fa-calendar'
                        label='Date Of Birth'
                        name='dateOfBirth'
                        value={formValues.dateOfBirth}
                        onChange={handleInputChange}
                        error={errors['dateOfBirth']}
                      />
                    </div>
                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        type='text'
                        placeholder='Enter a father name'
                        icon='fa-phone'
                        name='fatherName'
                        label='Father Name'
                        value={formValues.fatherName}
                        onChange={handleInputChange}
                        error={errors['fatherName']}
                      />
                    </div>

                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        name='motherName'
                        type='text'
                        placeholder='Enter a mother name'
                        icon='fa-pencil-alt'
                        label='Mother Name'
                        value={formValues.motherName}
                        onChange={handleInputChange}
                        error={errors['motherName']}
                      />
                    </div>
                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        type='text'
                        placeholder='Enter a mobile number'
                        icon='fa-phone'
                        name='mobileNumber'
                        label='Mobile Number'
                        maxLength={10}
                        value={formValues.mobileNumber}
                        onChange={handleInputChange}
                        error={errors['mobileNumber']}
                      />
                    </div>

                    <div className='form-group col-12 col-lg-6'>
                      <FormInput
                        name='schoolName'
                        type='text'
                        placeholder='Enter a school name'
                        icon='fa-pencil-alt'
                        label='School Name'
                        value={formValues.schoolName}
                        onChange={handleInputChange}
                        error={errors['schoolName']}
                      />
                    </div>

                    <div className='form-group col-12'>
                      <button
                        type='submit'
                        id='submit-btn'
                        className='vs-btn wave-btn'
                      >
                        Register
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <PaymentPopup
        togglePaymentPopup={togglePaymentPopup}
        showPaymentPopup={showPaymentPopup}
      />
    </>
  );
};

export default RegisterForm;
const validationRules = {
  childName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  dateOfBirth: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  fatherName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  motherName: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
  },
  mobileNumber: {
    presence: {
      allowEmpty: false,
      message: ' is required',
    },
    format: {
      pattern: /^\d{10}$/,
      message:
        ' should be 10 digits long and contain only numbers',
    },
  },
};
