import React, { useState } from 'react';
import tabsData from '../helpers/data';

const ServiceTabs = () => {
  const [activeTab, setActiveTab] = useState('nav-one');


  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  return (
    <section class='service-section bg-smoke-two space-double'>
      <div class='container'>
        <div class='title-area text-center wow fadeInUp' data-wow-delay='0.1s'>
          <h2 class='sec-title'>
            What Exactly is <span>iSTEAM ?</span>
          </h2>
        </div>
        <div className='row gx-lg-15 justify-content-center'>
          <div
            className='col-xl-2 col-md-6 order-xl-1 order-3 wow fadeInLeft'
            data-wow-delay='0.1s'
          >
            <div
              className='nav nav-tabs service-tab'
              id='service-tab1'
              role='tablist'
            >
              {tabsData.slice(0, 3).map((tab) => (
                <button
                  key={tab.id}
                  className={`sr-icon-btn ${
                    activeTab === tab.id ? 'active' : ''
                  }`}
                  onClick={() => handleTabClick(tab.id)}
                  id={`${tab.id}-tab`}
                  data-bs-toggle='tab'
                  data-bs-target={`#${tab.id}`}
                  type='button'
                  role='tab'
                  aria-controls={tab.id}
                  aria-selected={activeTab === tab.id ? 'true' : 'false'}
                >
                  <img src={tab.tabicon} alt='icon' />
                  <span className='text'>{tab.tabtitle}</span>
                </button>
              ))}
            </div>
          </div>

          <div
            className='col-xl-8 order-xl-2 order-2  wow fadeInUp'
            data-wow-delay='0.1s'
          >
            <div
              className='tab-content service-tabcontent'
              id='service-tabContent'
            >
              {tabsData.map((tab) => (
                <div
                  key={tab.id}
                  className={`tab-pane fade ${
                    activeTab === tab.id ? 'show active' : ''
                  }`}
                  id={tab.id}
                  role='tabpanel'
                  aria-labelledby={`${tab.id}-tab`}
                >
                  <div className='row align-items-center service-grid'>
                    <div className='col-md-6'>
                      <div className='service-img'>
                        <img src={tab.image} alt='service' />
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='service-icon'>
                        <img src={tab.icon} alt='icon' />
                      </div>
                      <h2 className='h3 service-title'>
                        <a href='/'>{tab.title}</a>
                      </h2>
                      <p className='service-text'>{tab.description}</p>
                      <div className='check-list style-3'>
                        <ul>
                          {tab.points.map((point, index) => (
                            <li key={index}>{point}</li>
                          ))}
                        </ul>
                      </div>
                      <p className='service-text'>{tab.researchText}</p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div
            className='col-xl-2 col-md-6 order-xl-3 order-1 wow fadeInRight'
            data-wow-delay='0.1s'
          >
            <div
              className='nav nav-tabs service-tab'
              id='service-tab2'
              role='tablist'
            >
              {tabsData.slice(3).map((tab) => (
                <button
                key={tab.id}
                className={`sr-icon-btn ${
                  activeTab === tab.id ? 'active' : ''
                }`}
                onClick={() => handleTabClick(tab.id)}
                id={`${tab.id}-tab`}
                data-bs-toggle='tab'
                data-bs-target={`#${tab.id}`}
                type='button'
                role='tab'
                aria-controls={tab.id}
                aria-selected={activeTab === tab.id ? 'true' : 'false'}
              >
                <img src={tab.tabicon} alt='icon' />
                <span className='text'>{tab.tabtitle}</span>
              </button>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceTabs;
