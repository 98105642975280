import axios from 'axios';
// import { removeCookie } from 'helpers/utis';
// import Cookies from 'js-cookie';
// import { logOutAct } from '@/redux/actions/authActions';

import config from '../config';
import { Toast } from './toast';

export const axiosInstance = axios.create({
  headers: {},
});

export const cancelTokenSource = axios.CancelToken.source();

// export const setAuthToken = (token) => {
//   Cookies.set('token', token, { expires: 7 });
//   return localStorage.setItem('token', token);
// };

export const getAuthToken = () => {
  return localStorage.getItem('token');
};

// export const getCookieToken = () => {
//   return Cookies.get('authToken');
// };

export const setLocalStorageData = (key, value) => {
  localStorage.setItem(key, value);
};

export const getLocalStorageData = (key) => {
  if (!key) return null;
  return JSON.parse(localStorage.getItem(key));
};

// export const removeAuthToken = () => {
//   if (typeof window !== 'undefined') localStorage.removeItem('token');
//   Cookies.remove('token');
//   Cookies.remove('authToken');
//   return true;
// };

export const logout = () => {
  axiosInstance.defaults.headers.common.Authorization = '';
  localStorage.clear();
  setTimeout(() => {
    window.location.pathname = '/';
  }, 200);
};

export const statusHelper = (status, data) => {
  if (data.status === 401 && cancelTokenSource) cancelTokenSource.cancel();

  if (data.status === 401 || data.status === 403) {
    Toast({ type: 'error', message: data.statusText });
    // setTimeout(() => logout(), 1000);
  }
  if (status) {
    return {
      status: data.status,
      ...data.data,
    };
  }
  return data.data;
};

export const scrollHelper = ({ isTop, isSmooth = true }) => {
  if (window) {
    const behavior = isSmooth ? 'smooth' : 'auto';

    if (isTop) {
      return window.scrollTo({ top: 0, behavior });
    }
  }

  return null;
};

export const isAuthorized = async () => {
  if (
    localStorage.getItem('token') !== null &&
    localStorage.getItem('token') !== undefined
  ) {
    return true;
  }
  return false;
};
