
import sample from "../assets/img/sample/5.jpeg";
// import about2 from "../assets/img/about/";
import dog from "../assets/img/icon/dog.png";
import star from "../assets/img/icon/star.png";


const About = () => {
    return(
        <section className="about-section topspace" id="about">
        <div className="container">
            <div className="row align-items-center gy-30">
                <div className="col-lg-6 wow fadeInLeft" data-wow-delay="0.1s">
                    <div className="img-box-2">
                        <img src={sample} className="big-img" alt="photo"/>
                        {/* <img src={about2} alt="photo" className="small-img"/> */}
                    </div>
                </div>
                <div className="col-lg-5 wow fadeInRight" data-wow-delay="0.1s">
                    <div className="text-center text-lg-start">
                        <span className="sub-title">About Miracle</span>
                        <p className="fs-md mb-10">Kindergarten & Daycare</p>
                        <p className="fs-md mb-30">
                            Everything we do comes from the heart. The excellent standards of care we provide stem from
                            how much our team care about each child's wellbeing, safety, and happiness, inside and
                            outside
                        </p>
                        <p className="fs-md mb-10">We Create childhood memories that will last a Litetime by consistently
                            Over - delivering on the standards.</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="shape-mockup shapePulse d-none d-hd-block" data-bottom="40%" data-left="6%">
            <img src={dog} alt="shapes"/></div>
        <div className="shape-mockup rotate d-none d-md-block" style={{  right: '6%' ,bottom: '40%'}}>
            <img src={star} alt="shapes"/></div>
    </section>
    )
}

export default About