import React, { useRef } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import slide1 from '../../assets/img/class/1.jpeg';
import slide2 from '../../assets/img/class/3.png';
import slide3 from '../../assets/img/class/5.jpeg';
import slide4 from '../../assets/img/class/6.jpeg';

const TestimonialSlider = () => {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 2000,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    prevArrow: null,
    nexrArrow: null,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
  };


  const slides = [
    {
      imgUrl: slide1,
      title: 'Pre KG',
      description: 'Our fun and caring environment',
      age: '2-4 Years',
      time: '9.30 am - 12.30 pm',
      capacity: 30,
    },
    {
      imgUrl: slide2,
      title: 'Pre KG',
      description: 'Our fun and caring environment',
      age: '2-4 Years',
      time: '9.30 am - 12.30 pm',
      capacity: 30,
    },
    {
      imgUrl: slide3,
      title: 'Pre KG',
      description: 'Our fun and caring environment',
      age: '2-4 Years',
      time: '9.30 am - 12.30 pm',
      capacity: 30,
    },
    {
      imgUrl: slide4,
      title: 'Pre KG',
      description: 'Our fun and caring environment',
      age: '2-4 Years',
      time: '9.30 am - 12.30 pm',
      capacity: 30,
    },
  ];

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };
  return (
    <div className='testi-slider-area'>
      <Slider {...settings} ref={sliderRef}>
        {slides.map((slide, index) => (
          <div key={index} className='row vs-carousel'>
            <div
              className='col-lg-12 col-md-12 wow fadeInUp slick-slide wow fadeInUp'
              data-wow-delay='0.1s'
            >
              <div className='class-card'>
                <div className='class-img'>
                  <a href='/'>
                    <img src={slide.imgUrl} alt={`class-${index + 1}`} />
                  </a>
                </div>
                <div className='class-content'>
                  <h2 className='class-title h4'>
                    <a href='/'>{slide.title}</a>
                  </h2>
                  <p className='class-text'>{slide.description}</p>
                  <ul className='class-info'>
                    <li>
                      <p>Age:</p>
                      <span>{slide.age}</span>
                    </li>
                    <li>
                      <p>Time:</p>
                      <span>{slide.time}</span>
                    </li>
                    <li>
                      <p>Capacity:</p>
                      <span>{slide.capacity}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className='vs-icon-box testi-1 d-none d-xl-block'>
        <button
          data-slick-prev='#testi1'
          className='icon-btn style-3 arrow-left mb-15'
          onClick={goToPrevSlide}
        >
          <i class='far fa-arrow-left' ></i>
        </button>
        <button
          data-slick-next='#testi1'
          className='icon-btn style-3 arrow-right'
          onClick={goToNextSlide}
        >
          <i class='far fa-arrow-right'></i>
        </button>
      </div>
    </div>
  );
};

export default TestimonialSlider;
