import React, { useState } from 'react';
import img1 from '../assets/img/project/1.jpg';
import img14 from '../assets/img/project/14.jpg';
import img15 from '../assets/img/project/16.jpeg';
import images from '../helpers/image';

const Gallery = () => {
  const [selectedCategory, setSelectedCategory] = useState('all');

  const tabTitles = ['All', 'Red Day', 'Yellow Day', 'Fancy Dress'];

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
  };

  return (
    <section className='project-section bg-smoke space-extra' id='gallery'>
      <div className='container'>
        <div
          className='row text-center justify-content-center wow fadeInUp'
          data-wow-delay='0.1s'
        >
          <div className='col-xl-6 col-lg-7 col-md-8 col-sm-9'>
            <div className='title-area'>
              <span className='sub-title'>Gallery</span>
              <h2 className='sec-title'>Amazing Activities Done By Our Kids</h2>
            </div>
          </div>
        </div>
        <div className='project-menu text-center mb-40 filter-menu-active'>
          {tabTitles.map((title, index) => (
            <button
              key={index}
              onClick={() => handleCategoryChange(title.toLowerCase())}
              className={
                selectedCategory === title.toLowerCase() ? 'active' : ''
              }
            >
              {title}
              <img
                className={
                  selectedCategory === title.toLowerCase()
                    ? 'btn-bg active'
                    : 'btn-bg'
                }
                src={require(`../assets/img/bg/btn-bg.png`)}
                alt='btn-bg'
              />
              <img
                className={
                  selectedCategory === title.toLowerCase()
                    ? 'btn-active-bg active'
                    : 'btn-active-bg'
                }
                src={require(`../assets/img/bg/btn-bg-active.png`)}
                alt='btn-active-bg'
              />
            </button>
          ))}
        </div>
        <div className='row g-20 justify-content-center filter-active'>
          {images
            .filter(
              (image) =>
                selectedCategory === 'all' ||
                image.category === selectedCategory
            )
            .map((image, index) => (
              <div
                key={index}
                className={`col-xl-3 col-lg-4 col-sm-6 filter-item ${image.category} wow fadeInUp`}
                data-wow-delay='0.1s'
              >
                <div className='project-card'>
                  <div className='project-img'>
                    <img src={image.src} alt={`project-${index}`} />
                  </div>
                  <a href={image.src} className='plus-btn popup-image'>
                    +
                  </a>
                </div>
              </div>
            ))}
        </div>
      </div>
      <div class='shape-before'>
        <img
          src={require('../assets/img/shape/before-shape-5.png')}
          alt='shape'
        />
      </div>
      <div class='shape-after'>
        <img
          src={require('../assets/img/shape/after-shape-5.png')}
          alt='shape'
        />
      </div>
      <div
        class='shape-mockup movingX d-none d-hd-block'
        data-top='15%'
        data-left='9%'
        style={{ top: '15%', left: '9%' }}
      >
        <img src={require('../assets/img/icon/duck.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup movingX d-none d-hd-block'
        data-bottom='15%'
        data-left='9%'
        style={{ top: '15%', left: '9%' }}
      >
        <img src={require('../assets/img/icon/butterfly-5.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup shapePulse d-none d-hd-block'
        data-top='14%'
        data-right='7%'
        style={{ top: '14%', right: '7%' }}
      >
        <img src={require('../assets/img/icon/sun-3.png')} alt='shapes' />
      </div>
      <div
        class='shape-mockup moving d-none d-sm-block'
        data-bottom='17%'
        data-right='6%'
        style={{ bottom: '17%', left: '6%' }}
      >
        <img src={require('../assets/img/icon/leaves.png')} alt='shapes' />
      </div>
    </section>
  );
};

export default Gallery;
