import img1 from '../assets/img/project/1.jpg'
import img2 from '../assets/img/project/2.jpg'
import img3 from '../assets/img/project/3.jpg'
import img4 from '../assets/img/project/4.jpg'
import img5 from '../assets/img/project/5.jpg'
import img6 from '../assets/img/project/6.jpg'
import img7 from '../assets/img/project/7.jpg'
import img8 from '../assets/img/project/8.jpg'
import img9 from '../assets/img/project/9.jpg'
import img10 from '../assets/img/project/10.jpg'
import img11 from '../assets/img/project/11.jpg'
import img12 from '../assets/img/project/12.jpg'
import img13 from '../assets/img/project/13.jpg'
import img14 from '../assets/img/project/14.jpg'
import img16 from '../assets/img/project/18.jpg'
import img18 from '../assets/img/project/20.jpg'
import img19 from '../assets/img/project/21.jpg'
import img20 from '../assets/img/project/22.jpg'
import img21 from '../assets/img/project/23.jpg'
import img23 from '../assets/img/project/36.jpeg'
import img24 from '../assets/img/project/33.jpeg'
import img25 from '../assets/img/project/34.jpeg'



 const images = [
    { src: img14, category: 'red day' },
    { src: img16, category: 'red day' },
    { src: img18, category: 'red day' },
    { src: img19, category: 'red day' },
    { src: img20, category: 'red day' },
    { src: img21, category: 'red day' },
    { src: img1, category: 'fancy dress' },
    { src: img2, category: 'fancy dress' },
    { src: img3, category: 'fancy dress' },
    { src: img23, category: 'fancy dress' },
    { src: img24, category: 'fancy dress' },
    { src: img25, category: 'fancy dress' },
    { src: img4, category: 'all' },
    { src: img5, category: 'all' },
    { src: img6, category: 'yellow day' },
    { src: img7, category: 'yellow day' },
    { src: img8, category: 'yellow day' },
    { src: img9, category: 'yellow day' },
    { src: img8, category: 'all' },
    { src: img9, category: 'all' },
    { src: img10, category: 'all' },
    { src: img11, category: 'all' },
    { src: img12, category: 'all' },
    { src: img13, category: 'all' },
   

]

export default images;