import React, { useRef, useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import LineOneImage from '../assets/img/shape/line-1.png';
import LineTwoImage from '../assets/img/shape/line-2.png';
import slide1 from '../assets/img/about/new_5.png';
import slide2 from '../assets/img/about/new_6.png';
import slide3 from '../assets/img/about/new_3.png';

const AboutSlider = () => {
  const slideData = [slide1, slide2, slide3];
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    beforeChange: (current, next) => {
      triggerAnimation(next); // Trigger animation on slide change
    },
    prevArrow: null,
    nexrArrow:null
  };

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickNext();
    }
  };

  const goToPrevSlide = () => {
    if (sliderRef.current) {
      sliderRef.current.slickPrev();
    }
  };

  const triggerAnimation = (index) => {
    const lines = document.querySelectorAll('.line-1, .line-2');
    lines.forEach((line) => {
      line.style.animation = 'none';
      setTimeout(() => {
        line.style.animation = `slideinup 0.5s ease forwards`;
      }, 50); // Adjust the timing
    });
  };

  useEffect(() => {
    triggerAnimation(0); // Initial animation on mount
  }, []);

  return (
    <div className="shape-slider-area">
      <button className='simple-arrow arrow-left' onClick={goToPrevSlide}>
        <i className='far fa-chevron-left'></i>
      </button>
      <Slider ref={sliderRef} {...settings}>
        {slideData.map((slide, index) => (
          <div key={index} className='slide-item'>
            <img className='line-1' src={LineOneImage} alt='shape' />
            <img className='line-2' src={LineTwoImage} alt='shape' />
            <img src={slide} alt={`Slide ${index + 1}`} />
          </div>
        ))}
      </Slider>
      <button className='simple-arrow arrow-right' onClick={goToNextSlide}>
        <i className='far fa-chevron-right'></i>
      </button>
    </div>
  );
};

export default AboutSlider;
