export const contestApi = {
  contestListApi: {
    url: '/api/contest',
    method: 'get',
  },
};

export const registerApi = {
  registerFormApi: {
    url: '/api/child/register',
    method: 'post',
  },
};
export const login = {
  loginApi: {
    url: '/api/child/login',
    method: 'post',
  },
};
export const uploadImage = {
  uploadImageApi: {
    url: '/api/upload/image',
    method: 'post',
  },
};
export const uploadVideo = {
  uploadvideoApi: {
    url: '/api/upload/video',
    method: 'post',
  },
};
export const uploadApi = {
  upload: {
    url: '/api/child/submit/contest',
    method: 'post',
  },
};
export const childApi = {
  getUser: {
    url: '/api/child/verify',
    method: 'get',
  },
getAllChildApi: {
    url: '/api/child/list',
    method: 'get',
  },
  getChildById: {
    url: '/api/child/detail',
    method: 'get',
  },
  registerVote:{
    url: '/api/child/contest/vote',
    method: 'post',
  },
  verifyOtp:{
    url: '/api/child/contest/vote/verify/otp',
    method: 'post',
  }
};
